import React, { Suspense } from "react";
import { Routes as ReactRoutes, Route } from "react-router-dom";
import { FullScreenLoading } from "./Components/FullScreenLoading";
import PublicLanding from "./pages/PublicLanding";

export interface PreviewProps {
  isPreview?: boolean;
}

// each page folder should be the equivalent of the legacy App Bundle (from Legacy App)
// the folder should have an index.tsx, creating a store, loading it, and importing the main <Component>
const ForgotPassword = React.lazy(() => import(/* webpackChunkName: "ForgotPassword" */'./pages/ForgotPassword'));
const ResetPassword = React.lazy(() => import(/* webpackChunkName: "ResetPassword" */'./pages/ResetPassword'));
const Login = React.lazy(() => import(/* webpackChunkName: "Login" */'./pages/Login'));
const NotFound = React.lazy(() => import(/* webpackChunkName: "Error" */'./pages/Error'));
const AccessDenied = React.lazy(() => import(/* webpackChunkName: "NotAuthorized" */'./pages/Error/NotAuthorized'));
const DevHome = React.lazy(() => import(/* webpackChunkName: "DevHome" */'./pages/DevHome'));
const UnknownUser = React.lazy(() => import(/* webpackChunkName: "UnknownUser" */'./pages/UnknownUser'));
const ConfirmEmailUpdate = React.lazy(() => import(/* webpackChunkName: "ConfirmEmailUpdate" */'./pages/ConfirmEmailUpdate'));

const RoutePaths = {
  Landing: "/",
  ForgotPassword: `/forgot-password`,
  ResetPassword: `/reset-password/:code`,
  ActivateAccount: `/activate-user`,
  UnknownUser: `/unknown-user`,
  AccessDenied: `/access-denied`,
  DevHome: `/dev`,
  Login: `/login`,
  ConfirmEmailUpdate: `/confirm-email-update`
}
const Router = (): JSX.Element => (
  <Suspense fallback={<FullScreenLoading />}>
    <ReactRoutes>
      <Route>
        <Route path={RoutePaths.ForgotPassword} element={<ForgotPassword />} />
        <Route path={RoutePaths.ResetPassword} element={<ResetPassword />} />
        <Route path={RoutePaths.ActivateAccount} element={<ResetPassword />} />
        <Route path={RoutePaths.Login} element={<Login />} />
        <Route path={RoutePaths.DevHome} element={<DevHome />} />
        <Route path={RoutePaths.UnknownUser} element={<UnknownUser />} />
        <Route path={RoutePaths.AccessDenied} element={<AccessDenied />} />
        <Route path={RoutePaths.ConfirmEmailUpdate} element={<ConfirmEmailUpdate />} />

        <Route index path={RoutePaths.Landing} element={<PublicLanding />} />

        {/* catch all */}
        <Route path="*" element={<NotFound />} />
      </Route>
    </ReactRoutes>
  </Suspense>
);

export {
  RoutePaths, Router
};

