import React from "react";

React.Component.prototype.setStateAsync = function (newState: unknown) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-this-alias
    const self: React.Component = this;

    return new Promise((resolve) => {
        self.setState(newState, () => {
            resolve()
        });
    });
};

