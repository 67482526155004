import "@scss/lib/full-screen-loading.scss";
import React, { FC } from "react";
import { Spinner } from "./Spinner";
interface Props {
    message?: string
}

const FullScreenLoading: FC<Props> = ({ message }) => {
    return <><div className="full-screen-loading-content">
        <Spinner />
        {message && <div>{message}</div>}
    </div>
        <div className="full-screen-loading-background"></div>
    </>;
};

export { FullScreenLoading };
