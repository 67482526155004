import "@scss/lib/spinner.scss";
import React, { FC } from "react";

const Spinner: FC = () => {
  return (
    <div className="spinner-container">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export { Spinner };
