import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights, DistributedTracingModes } from '@microsoft/applicationinsights-web';
import { createBrowserHistory } from 'history';
import React, { Component, PropsWithChildren } from 'react';
const browserHistory = createBrowserHistory();

interface Props {
    connectionString?: string,
    appVersion?: string
}

/**
 * This Component provides telemetry with Azure App Insights
 *
 * NOTE: the package '@microsoft/applicationinsights-react-js' has a HOC withAITracking that requires this to be a Class Component rather than a Functional Component
 */
class TelemetryProvider extends Component<PropsWithChildren<Props>> {
    static reactPlugin = new ReactPlugin();
    static appInsights?: ApplicationInsights;

    constructor(props: PropsWithChildren<Props>) {
        super(props);
        if (!TelemetryProvider.appInsights) {
            TelemetryProvider.appInsights = new ApplicationInsights({
                config: {
                    connectionString: props.connectionString,// <local> if de env, to do manual tracks if needed
                    instrumentationKey: !props.connectionString ? "<local>" : undefined,
                    disableTelemetry: process.env.NODE_ENV === 'development',
                    enableAutoRouteTracking: true,
                    extensions: [TelemetryProvider.reactPlugin],
                    enableCorsCorrelation: true,
                    distributedTracingMode: DistributedTracingModes.W3C,
                    correlationHeaderExcludedDomains: [
                        '*.googleapis.com'
                    ],
                    extensionConfig: {
                        [TelemetryProvider.reactPlugin.identifier]: { history: browserHistory }
                    }
                },
            });

            TelemetryProvider.appInsights.addTelemetryInitializer((envelope) => {
                const baseData = envelope.baseData;
                if (baseData?.uri && baseData.uri.charAt(0) === "/") {
                    baseData.uri = `${window.location.origin}${baseData.uri}`;
                }
            });

            TelemetryProvider.appInsights.loadAppInsights();
        }
        TelemetryProvider.appInsights.context.application.ver = props.appVersion || "0.0.0-dev";
    }

    render() {
        return (<>{this.props.children}</>);
    }
}

export default withAITracking(TelemetryProvider.reactPlugin, TelemetryProvider, null, "main");
export const getAppInsights = () => {
    if (TelemetryProvider.appInsights)
        return TelemetryProvider.appInsights;
    else
        throw new Error("Application Insights not initialized.");
}
export const getAppInsightsReactPlugin = () => TelemetryProvider.reactPlugin;