
import React from "react";
import { BrowserRouter } from "react-router-dom";

import { Router } from "./Routes";
import TelemetryProvider from "./TelemetryProvider";

console.log(process.env.APPVERSION);

const App = () => {

  return (
    <BrowserRouter>
      <TelemetryProvider
        connectionString={process.env.APPLICATIONINSIGHTS_CONNECTION_STRING}
        appVersion={process.env.APPVERSION}
      >
        <Router />
      </TelemetryProvider>
    </BrowserRouter>

  );
};

export { App };
